import React from "react";
import "./styles/hr.css";

const HorizontalRule = () => {
	return (
		<div className="horizontal-rule">
			<div />
		</div>
	);
};

export default HorizontalRule;
